.node-fps {
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .story-header {
    position: relative;
    img {
      max-height: 70vh;
    }
    .field-name-field-fps-quote {
      background-color: $brand-primary;
      color: white;
      padding: 3rem;
      font-style: italic;
      font-size: 2.5rem;
      @include breakpoint(md) {
        position: absolute;
        bottom: 100px;
        right: 20px;
        max-width: 400px;
      }
    }
  }
  .story-grid-wrapper {
    @include breakpoint(md) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    background-color: #f5f5f5;

    .story-text {
      @include breakpoint(md) {
        grid-column: 1;
      }
    }
    .story-grid-photos-wrapper {
      @include breakpoint(md) {
        grid-column: 2/4;
      }
      background-color: $brand-primary;
      .story-grid-photos {
        @include breakpoint(md) {
          height: 100%;
          min-height: 500px;
          grid-column: 2/4;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(2, 1fr);
        }
      }
      .story-grid-photos a:nth-child(1) {
        @include breakpoint(md) {
          grid-column: 1;
          grid-row: 1;
        }
      }
      .story-grid-photos a:nth-child(2) {
        @include breakpoint(md) {
          grid-column: 1;
        }
      }
      .story-grid-photos a:nth-child(3) {
        @include breakpoint(md) {
          grid-column: 2/4;
          grid-row: 1/3;
        }
      }
    }
    .field-name-body {
      padding: 2rem;
      @extend small;
    }
  }
  .story-additional-photos {
    @include breakpoint(md) {
      display: flex;
      flex-wrap: wrap;
      a {
        flex: 33.3333% 0 1;
      }
    }
    //img {
    //  display: block;
    //  width: 100%;
    //  height: 100%;
    //  object-fit: cover;
    //}
  }
}