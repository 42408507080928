.block-superfish {
  .block__title {
    @extend .sr-only;
  }

  #superfish-1-toggle {
    padding: 1.7rem;
    display: block;
    width: 100%;
    text-align: center;
    color: #000;

    &:before {
      @include fa(bars);
      font-size: 2.5rem;
    }

    &.sf-expanded {
      &:before {
        @include fa(times);
        font-size: 2.5rem;
      }
    }

    span {
      @extend .sr-only;
    }
  }

  // Shared styles
  .sf-style-none {
    li {
      a {
        padding: 1.2rem;
      }
    }

    // Top level menu items.
    a.sf-depth-1 {
      text-transform: uppercase;
    }
  }

  // Horizontal styles.
  .sf-horizontal {
    float: right;
    margin-top: 1.2rem;
  }
}
