.breadcrumbs {
  background-color: #f5f5f5;
  padding: 2rem 1.5rem;
  margin-top: 1rem;
  .container-smooth {
    .breadcrumb {
      padding: 0;
      margin: 0;
    }
  }
}