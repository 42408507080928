.node--showcase--full {
  position: relative;
  padding: 2rem 0;
  min-height: 50vh;
  display: flex;
  align-items: center;
  color: #fff;
  @extend .font-smoothing-on;
  text-align: center;

  h2 {
    color: #333;
  }

  .content {
    max-width: 900px;
    background: #fff;
    color: #333;
    padding: 2rem;
    border-radius: $border-radius-base;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 8px 8px rgba(0, 0, 0, 0.1);
  }

  .field-name-field-showcase-caption {
    font-size: 2rem;
  }

  .field-name-field-showcase-link {
    margin-top: 1rem;
  }
}
