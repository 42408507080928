body {
  background-color: $base-background;
  color: $accessible-grey-offwhite;
}

.front .page-title {
  @extend .sr-only;
}

.page-title {
  padding-bottom: 1.5rem;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 170px;
    height: 2px;
    left: 0;
    bottom: 0;
    background-color: $brand-primary;
  }
}

.page-node {
  .main-content {
    padding-top: 1rem;
  }
}