.node--program--teaser {
  .field-name-field-image {
    /* display: block;
    margin: 1rem 2rem;
    @include breakpoint(sm) {
      float: right;
      margin-top: -4rem;
    } */
    img {
      @extend .img-thumbnail;
    }
  }
}