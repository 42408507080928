.node--showcase--teaser {
  margin: 0 auto (2 * $vertical-spacing-unit);
  max-width: 600px;
  box-shadow: 8px 8px rgba(0, 0, 0, 0.1);

  .field-name-field-image {
    margin-bottom: $vertical-spacing-unit;

    img {
      width: 100%;
    }
  }

  .node-title {
    margin-bottom: 0;
  }

  .field-name-field-showcase-link {
    margin-top: $vertical-spacing-unit;
  }
  .field-name-field-link {
    text-align: right;
  }
}
