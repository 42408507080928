.node--resource--teaser.node-teaser {
  border-bottom: 0;
  padding-bottom: 0;
}
.node--resource--teaser {
  box-shadow: inset 0 -2px 0 rgba( 0, 0, 0, 0.25 );
  h3 {
    margin: 0;
    font-size: 1.8rem;
  }
  .label-group {
    float: right;
    @include clearfix;
    margin: 0 1rem;
    .label {
      margin: 0 0.2rem;
    }
  }
  .field-name-body {
    clear: both;
    overflow: hidden;
    @extend .small;
    padding: 0;
  }
}