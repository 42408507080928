.field {
  padding-bottom: 1rem;
  .field-label {
    font-weight: 800;
    font-size: 1.8rem;
  }
}

.field-label-inline {
  .field-label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
  .field-items {
    display: inline-block;
    vertical-align: middle;
  }
}

.btn{
  white-space:normal !important;
  word-wrap:break-word; 
}