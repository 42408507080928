.page-header {
    position: relative;
    margin: 0;
    border-bottom: 0;
    padding: 2rem 0;
    //background: $brand-info;

    /* &:before {
      content: "";
      @extend .pos-abs;
      @extend .pattern-overlay;
      @extend .health;
      @extend .opacity-4;
    } */

  /* .header-navbar {
    margin-top: 1rem;
  } */

  .site-logo {
    height: 70px;
    @include breakpoint(sm) {
      float: left;
    }
    /* max-width: 500px;
    display: block;
    margin: 0 auto;
    height: 100%;
    width: auto; */
    /* @include breakpoint(md) {
      max-width: 300px;
      float: left;
      margin: 1rem 0;
      margin-right: $navbar-padding-horizontal;
    } */
  }

  .site-name {
    //max-width: 500px;
    /* display: block;
    margin: 0 auto;
    height: 100%;
    width: auto; */
      font-size: 1.6rem;
      font-weight: bold;
      text-transform: uppercase;
    font-family: $local_header_font;
    @include breakpoint(sm) {
      padding-top: 2rem;
      float: left;
    }
    /* @include breakpoint(md) {
      max-width: 400px;
      float: left;
      margin: 1rem 0;
      margin-right: $navbar-padding-horizontal;
    } */
    //@extend .sr-only;
  }
}

.breadcrumbs {
  ul {
    margin: 0;
    padding: 0;
    white-space: nowrap;

    li {
      list-style: none;
      display: inline;

      @include breakpoint(xs) {
        display: none;

        &.first,
        &.last {
          display: inline;
        }
      }
    }
  }

  .breadcrumb-seperator {
    margin: 0 1rem;
  }
  a {
    display: inline-block;
  }
}