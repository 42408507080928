html {
  font-size: $font-size-root;
  overflow-x: hidden;
}

.more-link a{
  @extend .btn;
  @extend .btn-primary;
}

h1,
h2,
h3,
h4,
h5,
h6,
page-title,
pane-title,
block-title {
  font-family: $local_header_font;
}