// Panel accordion toggle indicators.
.accordian-indicator-processed {
  .panel-heading.active {
    background-color: #444;
  }
  .panel-title {
    position: relative;

    .accordion-toggle,
    [data-toggle="collapse"] {
      display: block;
      padding-right: 2rem;
      position: relative;
      z-index: 1;
    }

    .accordion-toggle-indicator {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -10px;
    }
  }
}
// Local override.
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}