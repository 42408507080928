.panel-group {
  margin-top: $line-height-computed;
}

.panel {
  border: 0;
  .panel-title {
    a {
      text-decoration: none;
    }
  }
  .panel-body {
    background-color: #f1f1f1;
    color: #232323;
  }
}