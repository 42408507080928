.front {
  .pane-showcases-showcase-homepage-grid {
    background-color: #776faa;
    padding: 6rem 0 4rem 0;
    position: relative;
    &:before {
      content: "";
      @extend .pos-abs;
      @extend .pattern-overlay;
      @extend .swirl;
      @extend .opacity-3;
    }
    .node--showcase--teaser {
      background-color: $base-background;
      .content {
        padding: 1rem;
      }
    }
  }
}