.menu-block-1 {
  .nav {
    li {
      margin-top: 0;
    }
    a {
      position: relative;
      display: block;
      padding: 10px 15px;
      margin-bottom: -1px;
      background-color: $list-group-bg;
      border: 1px solid $list-group-border;
      color: $list-group-link-color;
      &:hover,
      &:focus {
        text-decoration: none;
        color: $list-group-link-hover-color;
        background-color: $list-group-hover-bg;
      }
    }
  }
  .nav > .leaf:first-of-type {
    a {
      border-top-left-radius: $list-group-border-radius;
      border-top-right-radius: $list-group-border-radius;
    }
  }
  .nav > .leaf:last-of-type {
    a {
      border-bottom-left-radius: $list-group-border-radius;
      border-bottom-right-radius: $list-group-border-radius;
      margin-bottom: 0;
    }
  }
}