ul.links.inline {
  list-style-type: none;
  margin: 0;
  padding: 1rem 0;
  margin-top: 1.5rem;
  @extend .clearfix;

  a {
    @extend .btn;
    @extend .btn-default;
    float: left;
    margin-right: 1rem;
  }
}