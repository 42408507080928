// Texture path.
$texture-path: '../images/textures';

// Font weights.
$fw-thin: 100; //*
$fw-light: 300;
$fw-regular: 400;  //*
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;  //*
$fw-extrabold: 800;
$fw-black: 900;

// Font weight kits, re-map font-weights that are not in our kit.
$fw-light: $fw-thin;
$fw-medium: $fw-regular;
$fw-semibold: $fw-bold;
$fw-extrabold: $fw-bold;
$fw-black: $fw-bold;

// Measurements.
$toolbar-menu-height: 30px;
$toolbar-drawer-height: 34px;
$toolbar-menu-and-drawer-height: $toolbar-drawer-height + $toolbar-menu-height;
$ui-bar-height: 41px;

// Site dependent Measurements.
$header-height: 62px;
$primary-tabs-height: 37px;

// Calculated measurements.
$is_logged_in_with_drawer: $toolbar-menu-and-drawer-height + $ui-bar-height + $header-height + $primary-tabs-height;
$is_logged_in_with_drawer_no_tabs: $toolbar-menu-and-drawer-height + $ui-bar-height + $header-height;
$is_logged_in_without_drawer: $toolbar-menu-height + $ui-bar-height + $header-height + $primary-tabs-height;
$is_logged_in_without_drawer_no_tabs: $toolbar-menu-height + $ui-bar-height + $header-height + $primary-tabs-height;
$is_not_logged_in: $ui-bar-height + $header-height;

// Spacing measurements.
$field-vertical-spacing: 1rem;
$vertical-spacing-unit: 1.5rem;
$main-wrapper-padding: 3rem;

// Breakpoints
$text-max-width: 900px;
$sf-expanded: 960px;

// Region Background Colors.
$base-background: #fdfdfd;

// Brand Colors.
/* $brand-primary: #008080;
$brand-secondary: #913d88;
$brand-tertiary: #ba372a;; */
$brand-yellow: #ffce00;
$brand-old-gold: #81642b;

// Text color styles.
$disabled-text: #888;
$link-visited: $brand-primary;
$accessible-grey: #797676;
$accessible-grey-offwhite: #666666;
